<template>
    <!-- Logo ve banner -->
      <BlocklyComponent :toolboxXml="toolboxXml" :workspaceXml="workspaceXml"></BlocklyComponent>
</template>

<script>
import BlocklyComponent from "./components/BlocklyComponent";
// import Arduino from "./components/Arduino.vue"
import workspaceXml from "!!raw-loader!./assets/25mart/workspace.xml";
import toolboxXml from "!!raw-loader!./assets/25mart/toolbox.xml";
// import library from "!!raw-loader!./assets/25mart/library.xml";

export default {
  name: "App",
  components: {
    BlocklyComponent,
    // Arduino
 
  },
  
  setup() {
   
  },
  data() {
    return {
      toolboxXml: toolboxXml,
      workspaceXml: workspaceXml,

      // library: library
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style>
#app {

  background-color: #74081d;
  font-family: 'Montserrat';
}
.banner {
  background-image: url("assets/deneyapkart.png");
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
} 
</style>