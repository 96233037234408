<template>
   <!-- Arduino kodları -->
   <div class="container-fluid">
   <div class="row">
      <!-- Button trigger modal -->
      <div class="col-1 mt-2 mb-2 p-0 m-2" >
          <div class="dropdown" style="width: 100%">
          <button style="width: 100%" class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Dosya
          </button>
          <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
            <li><button class="dropdown-item" @click="refreshPage">Yeni</button></li>
            <li><button class="dropdown-item" @click="downloadFile">Dosyayı indir</button></li>
            
            <li><a class="dropdown-item" href="#">Örnekler</a></li>
          </ul>
          </div>
      </div>
      <div class="col-2 mt-2 mb-2 " >
        <button style="width: 100%;" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="kodugoster" >
        C++ Kodunu Göster
        </button>
      </div>
      <div class="col-1 mt-2 mb-2 p-0" style="width: auto;">
        <button title="Geri Al" @click="triggerUndo" type="button" class="btn btn-light " data-bs-dismiss="#exampleModal" style="border-radius: 40px; ">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
        </svg>
    
      </button>
      <button title="İleri Al" @click="triggerRedo" type="button" class="btn btn-light" data-bs-dismiss="#exampleModal" style="border-radius: 40px; ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
        </svg>
          
      </button>
      </div>
      <div class="banner col-4 mt-2 mb-2 p-0" style="">

      </div>



      <div class="col-2 mt-2 mb-2 p-0" title="Kart bilgileri için tıklayın.">   
        <div class="row p-0 m-0">
          <div class="col-1 p-0 m-0">
              <svg viewBox="0 0 16 16" type="button" data-bs-toggle="modal" data-bs-target="#info" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" class="mt-1 bi bi-info-circle-fill" >
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
              </svg>
          </div>
          <div class="col-1 p-0 m-0"></div>
          <div class="col-10 p-0 m-0"> 
           
          <select class="form-select" aria-label="Default select example" v-model="selectedBoard">
            <option disabled value="">Kart Seçiniz</option>
            <option value="Deneyap Kart">Deneyap Kart</option>
            <option value="Deneyap Kart 1A">Deneyap Kart 1A</option>
            <!-- <option value="Deneyap Kart 1A V2">Deneyap Kart 1A V2</option>
            <option value="Deneyap Kart Mini">Deneyap Kart Mini</option>
            <option value="Deneyap Kart Mini V2">Deneyap Kart Mini V2</option>
            <option value="Deneyap Kart G">Deneyap Kart G</option> -->
          </select>
          </div>
        </div>
<!-- 
        <button style="" class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#info" >
          Kart: 
          <b>{{selectedText}}</b>
        </button> -->

          <!-- Modal -->
          <div class="modal fade" id="info" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Kart Bilgileri</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p v-if="selectedBoard=='YOK'">Bağlı kart yok, detaylar gösterilemiyor.</p>
                  <img v-if="selectedBoard=='Deneyap Kart'" src="../assets/pinler/deneyapkart.png" class="img-fluid" alt="...">
                  <img v-if="selectedBoard=='Deneyap Kart 1A'" src="../assets/pinler/deneyapkart1a.png" class="img-fluid" alt="...">
                  <img v-if="selectedBoard=='Deneyap Kart 1A V2'" src="../assets/pinler/deneyapkart1av2.png" class="img-fluid" alt="...">
                  <img v-if="selectedBoard=='Deneyap Kart Mini'" src="../assets/pinler/deneyapkartmini.png" class="img-fluid" alt="...">
                  <img v-if="selectedBoard=='Deneyap Kart Mini V2'" src="../assets/pinler/deneyapkartminiv2.png" class="img-fluid" alt="...">
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
                </div>
              </div>
            </div>
          </div>

      </div>


      <div class="col-1 mt-2 mb-2" title="Kodu derler.">
        <button style="width: 100%;" type="button" class="btn btn-light " data-bs-toggle="modal" data-bs-target="#exampleModal2" @click="compileCode">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
          </svg>
          Derle
      </button>
      </div>

      <div class="col-1 mt-2 mb-2 " title="Kodu yükler.">
        <button style="width: 100%;" type="button" class="btn btn-light " data-bs-toggle="modal" data-bs-target="#exampleModal2" @click="uploadFiles">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
          </svg>
          Yükle
      </button>
      </div>

<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="connected">
                <div v-if="derleme" class="alert alert-warning" role="alert">
                  Derleniyor...
                </div>
                <div v-if="responseDerle">
                  <div class="alert alert-success" role="alert">
                    Derleme Başarılı!
                  </div>
                </div>
                <div v-if="errorDerle">
                  <div class="alert alert-danger" role="alert">
                    Derleme Başarısız!
                  </div>
                </div>
                <div v-if="yukleme" class="alert alert-warning" role="alert">
                  Yükleniyor...
                </div>
                
                <div v-if="responseYukle">
                  <div class="alert alert-success" role="alert">
                    Yükleme Başarılı!
                  </div>
                </div>
                <div v-if="errorYukle">
                  <div class="alert alert-danger" role="alert">
                    Yükleme Başarısız!
                  </div>
                </div>
        </div>
        <div v-else>
          <div class="alert alert-danger" role="alert">
            Kart Seçilmedi veya Bağlanmadı!
          </div>
          
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>

      </div>
    </div>
  </div>
</div>
      <button @click="toggleConnection" :style="{ backgroundColor: connected ? 'green' : 'red', color: 'white' }">{{ connected ? 'Cihaz Bağlandı (bağlantıyı kesmek için tıkla) ' : 'Cihaz Bağlı Değil (bağlamak için tıkla)' }}</button>
      <!-- Modal -->
     <!-- <Arduino ></Arduino> -->

    </div>
  </div>




    <div ref="blocklyDiv" style="height: 90vh; position: relative; width: 100%"></div>
   <!-- Modal -->
   <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">C++ Kodu</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <v-ace-editor
            v-model:value="content"
            lang="json"
            theme="chrome"
            style="height: 400px;"    
          />
        </div>

        <div class="modal-footer">
          <button type="button" @click="downloadFile" class="btn btn-warning" data-bs-dismiss="" >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>
            Kodu indir
          </button>
          <button type="button" class="btn btn-success" @click="compileCode">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
            </svg>
            Derle
          </button>
          <button  type="button" class="btn btn-danger"  @click="uploadFiles">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
            </svg>
            Yükle
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Kapat
            </button>
        </div>
        <div v-if="derleme" class="alert alert-warning" role="alert">
          Derleniyor...
        </div>
        <div v-if="responseDerle">
          <div class="alert alert-success" role="alert">
            Derleme Başarılı!
          </div>
        </div>
        <div v-if="errorDerle">
          <div class="alert alert-danger" role="alert">
            Derleme Başarısız!
          </div>
        </div>

        <div v-if="yukleme" class="alert alert-warning" role="alert">
          Yükleniyor...
        </div>
        <div v-if="responseYukle">
          <div class="alert alert-success" role="alert">
            Yükleme Başarılı!
          </div>
        </div>
        <div v-if="errorYukle">
          <div class="alert alert-danger" role="alert">
            Yükleme Başarısız!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>   
    
    <script>
import { ref, onMounted, onUnmounted } from "vue";
import Blockly from "blockly";
import Tr from "blockly/msg/tr";
import customBlocks from "../assets/25mart/tanimlama.js";
import {javascriptGenerator, Order} from 'blockly/javascript';
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import axios from 'axios';
import 'ace-builds/src-noconflict/ext-language_tools';
import { ESPLoader, Transport } from 'esptool-js'; // ESPLoader ve Transport sınıflarını doğru içe aktarın




export default {
  data() {
    return {
      port: null,
      connected: false,
      busy:false,
      responseDerle: null,
      responseYukle: null,
      errorDerle:null,
      errorYukle:null,
      derleme:false,
      yukleme:false,
      selectedBoard: "",
      files: [],
      selectedPort: null,
      uploadStatus: '',
      bootloader: null,
      partition: null,
      firmware: null,
      baudrate: 115200,
      transport: null,
      espLoader: null,
      terminal:null,
      fqbn:null,
      chipType:null,
      codeValue:null,
      board:null

    };
  },
  props: {
    toolboxXml: String,
    workspaceXml: String,
  },
  components:{
    VAceEditor
  },
  methods: {


async uploadFiles() {
  await this.compileCode();
  this.responseDerle = false;
  this.errorDerle = false;
  this.responseYukle = false;
  this.errorYukle = false;
  this.derleme = false;
  this.yukleme = true;
  try {
    if (!this.port) {
      throw new Error('Port mevcut değil.');
    }
    await this.port.close();
    const terminal = {
      clean() {
        console.log('Terminal temizlendi.');
      },
      writeLine(data) {
        console.log('Satır yaz:', data);
      },
      write(data) {
        console.log('Veri yaz:', data);
      },
    };

    if (!this.transport) {
      throw new Error('Transport mevcut değil.');
    }
    console.log('ESPLoader öncesi Transport:', this.transport);
    console.log("Selected Board:", this.selectedBoard);

    const loader = new ESPLoader({
      transport: this.transport,
      baudrate: this.baudrate,
      terminal: terminal,
    });
    console.log("Loader oluşturuldu:", loader);
  
    
    console.log("Port kapatıldı");
    console.log("terminall", terminal);
    // Chip tipi tespiti
    const chipType2 = await loader.main();
    console.log('Tespit edilen chip tipi:', JSON.stringify(chipType2, null, 2));

    // Chip özelliklerini ve frekansını al
    const chipDescription = await loader.chip.getChipDescription(loader);
    const chipFeatures = await loader.chip.getChipFeatures(loader);
    const crystalFreq = await loader.chip.getCrystalFreq(loader);
    // const getFlashSize = await loader.chip.getFlashSize(loader);
    // console.log("flashsize"+ getFlashSize);
    console.log("Chip açıklaması:", chipDescription);
    console.log("Chip özellikleri:", chipFeatures);
    console.log("Crystal frekansı:", crystalFreq);


    // Offset değerleri
    const bootloaderOffset = 0x1000; // Chip açıklamasına göre ayarlayın
    const partitionOffset = 0x8000;  // Chip açıklamasına göre ayarlayın
    const firmwareOffset = 0x10000; // Chip açıklamasına göre ayarlayın



    // Binary string verilerini Uint8Array formatına dönüştür
    const bootloaderData = await loader.ui8ToBstr(this.bootloader);
    // console.log("Bootloader verisi:", bootloaderData);
    const partitionData = await loader.ui8ToBstr(this.partition);
    // console.log("Partition verisi:", partitionData);
    const firmwareData = await loader.ui8ToBstr(this.firmware);
    // console.log("Firmware verisi:", firmwareData);

    const options = {
      fileArray: [
        {
          data: bootloaderData,  // Bootloader verisi
          address: bootloaderOffset
        },
        {
          data: partitionData,  // Partition table verisi
          address: partitionOffset
        },
        {
          data: firmwareData,  // Firmware verisi
          address: firmwareOffset
        },
      ],
      flashSize: chipFeatures.flashSize || "4MB" ,   // Chip özelliklerine göre ayarlayın
      eraseAll: true,           // Flash belleğin tamamını sil
      flashMode: chipFeatures.flashMode || "DIO",   // Chip özelliklerine göre ayarlayın
      flashFreq: crystalFreq + "MHz" || "40MHz",   // Chip'in crystal frekansı
      compress: true, 

    };

    // `writeFlash` fonksiyonunu çağırın
    await loader.writeFlash(options);
    
    // Flash işlemini tamamlayın
    await loader.flashFinish();
    await loader.hardReset();
    console.log('Yükleme başarılı');
    this.yukleme = false;
    this.responseYukle=true;

  } catch (error) {
    console.error('Yükleme başarısız:', error);
    this.yukleme = false;
    this.errorYukle=true;
  }
},
    triggerUndo() {
      Blockly.Events.setGroup(true);
      this.workspace.undo(false);
      Blockly.Events.setGroup(false);
    },
    triggerRedo() {
      Blockly.Events.setGroup(true);
      this.workspace.undo(true);
      Blockly.Events.setGroup(false);
    },
    async toggleConnection() {
      if (this.busy) return;
      this.busy = true;
      console.log('toggleConnection çağrıldı');
      try {
        if (this.connected) {
          console.log('Bağlantıyı kesme işlemi');
          await this.closePort();
        } else {
          console.log('Bağlanma işlemi');
          await this.openPort();
        }
      } finally {
        this.busy = false;
      }
    },

    async openPort() {
      console.log('openPort çağrıldı');
      try {
        if (this.port) {
          if (this.port.readable) {
            console.log('Port açık, kapatılıyor...');
            await this.port.close();
            this.port = null; // Port nesnesini sıfırla
          }
        }
        // USB bağlantılı portları listelemek için filtreleme
        const ports = await navigator.serial.getPorts();
        
        console.log('USB bağlantılı portlar:', ports);

          // Kullanıcıdan bir USB port seçmesini isteme
          this.port = await navigator.serial.requestPort();
       
        if (!this.port) {
          throw new Error('Port seçilemedi.');
        }

        // Portu aç ve baudRate ayarla
        await this.port.open({ baudRate: 115200 });
        console.log('Port nesnesi:', this.port);

                // Portun açılıp açılmadığını doğrulama
        if (this.port.readable && this.port.writable) {
          console.log('Port başarılı şekilde açıldı ve özellikler mevcut.');
        } else {
          console.log('Port özelliklerine erişilemiyor.');
        }
        this.connected = true;

        // Port bilgilerini al
        const portInfo = await this.port.getInfo();
        // this.transport = new Transport(portInfo);
    
        // console.log('Transport:', this.transport);
        this.vendorId = portInfo.usbVendorId.toString(16).toUpperCase();
        this.productId = portInfo.usbProductId.toString(16).toUpperCase();
        if(this.productId=="2") this.productId="0002";
        this.transport = new Transport(this.port, this.vendorId , this.productId);
        console.log('Transport:', this.transport);

        console.log('thisportt', this.port);
        console.log('Seçilen port:', portInfo);
        console.log('Vendor ID:', this.vendorId);
        console.log('Product ID:', this.productId);

        if (this.vendorId && this.productId) {
          if(this.vendorId=="10C4" && this.productId== "EA60")
          {
            this.selectedBoard="Deneyap Kart";
      
          }
          // if(this.vendorId=="303A" && this.productId== "1001")
          // {
          //   this.selectedBoard="Deneyap Kart 1A V2"; //Deneyap Kart G içinde aynı değerler.
          //   // alert(`Bağlantı başarılı: Deneyap Kart 1A V2`);
          // }
          // if(this.vendorId=="303A" && this.productId== "0002")
          // {
          //   this.selectedBoard="Deneyap Kart Mini";
          //   // alert(`Bağlantı başarılı: Deneyap Kart Mini`);
          // }
          // if(this.vendorId=="303A" && this.productId== "8144")
          // {
          //   this.selectedBoard="Deneyap Kart Mini V2";
          //   // alert(`Bağlantı başarılı: Deneyap Kart Mini V2`);
          // }

        } else {
          console.log('Port bilgileri alınamadı.');

        }
      } catch (error) {
        console.error('Bağlantı hatası:', error);
        alert('Bağlantı hatası: ' + "Kart Seçilmedi");
      }
    },

    async closePort() {
      this.selectedBoard="";
      console.log('closePort çağrıldı');
      this.port = null;
      alert('Bağlantı kapatıldı');
      this.connected = false;

    },

// Web Sunucu tarafı
async compileCode() {
  this.responseDerle = false;
  this.errorDerle = false;
  this.responseYukle = false;
  this.errorYukle = false;
  this.yukleme = false;
  this.derleme = true;

  try {
    const response = await axios.post('http://localhost:1111/compile', {
      codeValue: this.content,
      board: this.selectedBoard,
    }, {
      headers: {    
        'Content-Type': 'application/json'
      }
    });

    // Yanıtın durum koduna göre işlem yap
    if (response.status === 200) {
      this.responseDerle = true;
      this.errorDerle = null;
      console.log('Derleme Başarılı:', response.data);
      // Sunucu tarafı
      const outputs = response.data.data.outputs;
      if (Array.isArray(outputs) && outputs.length >= 3) {
        
        this.loadFileFromBase64('firmware', outputs[0].base64);
        this.loadFileFromBase64('bootloader', outputs[1].base64);
        this.loadFileFromBase64('partition', outputs[2].base64);
      } else {
        console.log('Beklenen formatta veriler mevcut değil:', response.data);
      }
    } 
    else if (response.status === 400) {
      this.responseDerle = false;
      this.errorDerle = 'Derleme hatası';
      console.log("Derleme hatası:", response.data);
      // Derlenen dosyaları işlemek için
    } 
    else {
      this.responseDerle = null;
      this.errorDerle = 'Sunucu bağlantı hatası: İstek başarısız';
      console.log("Sunucu bağlantı hatası:", response.status);
    }
  } catch (error) {
    console.error('İstek hatası:', error.response || error.message);
    this.errorDerle = 'Sunucu bağlantı hatası: ' + (error.response?.data?.error || error.message);
    this.responseDerle = null;
  } finally {
    this.derleme = false;
  }
},

loadFileFromBase64(type, base64) {
    // Base64 verilerini Uint8Array formatına dönüştürün
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    
    // Yüklenen dosyayı uygun değişkene ata
    this[type] = byteArray;
    console.log(`${type} dosyası yüklendi:`, this[type]);
  },


    // async compileCode() {
    //   this.responseDerle = false;
    //   this.errorDerle = false;
    //   this.responseYukle = false;
    //   this.errorYukle = false;
    //   this.yukleme = false;
    //   this.derleme = true;
    //   try {
    //     const res = await axios.post('http://localhost:3000/compile', { 
    //       code: this.content,
    //       vendorId: this.vendorId,
    //       productId: this.productId,
    //       selectedBoard: this.selectedBoard,
    //     });
    //     if (res.status === 200) {
    //       this.responseDerle = res.data.compileOutput;
    //       this.errorDerle = null;
    //       console.log("Derleme başarılı:", this.responseDerle);
    //     } else {
    //       this.responseDerle = null;
    //       this.errorDerle = 'Sunucu bağlantı hatası: İstek başarısız';
    //       console.log("Sunucu bağlantı hatası:", res.status);
    //     }
    //   } catch (error) {
    //     console.error('İstek hatası:', error.response || error.message);
    //     this.errorDerle = 'Sunucu bağlantı hatası: ' + (error.response?.data?.error || error.message);
    //     this.responseDerle = null;
    //   } finally {
    //     this.derleme = false;
    //   }
    // },

    // async uploadCode() {
    //   this.responseDerle = false;
    //   this.errorDerle = false;
    //   this.responseYukle = false;
    //   this.errorYukle = false;
    //   this.derleme = false;
    //   this.yukleme = true;
    //   try {
         
    //     const res = await axios.post('http://localhost:3000/upload', {
    //       code: this.content,
    //       vendorId: this.vendorId,
    //       productId: this.productId,
    //       selectedBoard: this.selectedBoard,
    //     } );

        
    //     if (res.status === 200) {
    //       this.responseYukle = res.data.uploadOutput;
    //       this.errorYukle = null;
    //       console.log("Yükleme başarılı:", this.responseYukle);
    //     } else {
    //       this.responseYukle = null;
    //       this.errorYukle = 'Sunucu bağlantı hatası: İstek başarısız';
    //       console.log("Sunucu bağlantı hatası:", res.status);
    //     }
    //   } catch (error) {
    //     console.error('İstek hatası:', error.response || error.message);
    //     console.log('Detaylı hata bilgisi:', error.response);
    //     this.errorYukle = 'Sunucu bağlantı hatası: ' + (error.response?.data?.error || error.message);
    //     this.responseYukle = null;
    //   } finally {
    //     this.yukleme = false;
    //   }
    // },



    downloadFile() {
      // .ino dosyasının içeriğini al
      const inoContent = this.content;

      // Blob oluştur
      const blob = new Blob([inoContent], { type: "text/plain" });

      // Blob URL oluştur
      const url = URL.createObjectURL(blob);

      // Dosyayı indirmek için bir bağlantı oluştur ve otomatik olarak tıkla
      const link = document.createElement("a");
      link.href = url;
      link.download = "deneyap_blok.ino"; // Dosya adı
      document.body.appendChild(link);
      link.click();

      // Temizlik yap
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    kodugoster() {
      this.responseDerle=null,
      this.errorDerle=null,
      this.responseYukle=null,
      this.errorYukle=null,
      this.derleme=false,
      this.yukleme=false
    },
    refreshPage() {
      this.$router.go(0);
    },
  },

  setup(props) {
    const blocklyDiv = ref(null);
    const workspace = ref(null);
    const content = ref("");
    var include = [];
    var setup=[];
    var loop=[];
    var allGeneratedCode;
    const deneyapBlok = Blockly.Theme.defineTheme('my-theme', {
      base: Blockly.Themes.Classic, // Başka bir tema seçeneği de kullanılabilir
      blockStyles: {
          // Blok stilleri burada tanımlanır
      },
      categoryStyles: {
          // Kategori stilleri burada tanımlanır
          
      },
      componentStyles: {
          // Bileşen stilleri burada tanımlanır
          'workspaceBackgroundColour': '#BBDEFB',
          'toolboxBackgroundColour': 'white',
          'toolboxForegroundColour': 'black',
          'flyoutBackgroundColour': '#FFCC80',
          'flyoutForegroundColour': 'black',
          'flyoutOpacity': 1,
          'scrollbarColour': '#dc143c',
          'insertionMarkerColour': '#fff',
          'insertionMarkerOpacity': 1,
          'scrollbarOpacity': 0.7,
      },
      fontStyle: {
          'family': 'Montserrat',// Yazı tipi stilleri burada tanımlanır
      },
      startHats: true,// Başlık stilleri burada tanımlanır
  });
    onMounted(() => {
        Blockly.setLocale(Tr);
        
        const options = {
            collapse: true,
            comments: true,
            disable: true,
            maxBlocks: Infinity,
            trashcan: true,
            horizontalLayout: false,
            toolboxPosition: "start",
            css: true,
            media: "https://blockly-demo.appspot.com/static/media/",
            rtl: false,
            scrollbars: true,
            sounds: true,
            oneBasedIndex: true,
            theme: deneyapBlok,
            zoom: {
              controls: true,
              wheel: true,
              startScale: 0.8,
              maxScale: 3,
              minScale: 0.3,
              scaleSpeed: 1.2,
            },
            grid: {
              spacing: 0,
              length: 1,
              snap: true,
            },
        };

        workspace.value = Blockly.inject(blocklyDiv.value, {
            toolbox: props.toolboxXml,
            ...options,
        });

        if (props.workspaceXml) {
            const parser = new DOMParser();
            const domDocument = parser.parseFromString(
            props.workspaceXml,
            "text/xml"
            );
            Blockly.Xml.appendDomToWorkspace(
            domDocument.documentElement,
            workspace.value // Not: workspace yerine  workspace.value kullanıyoruz
            );
        }
        if (typeof Blockly !== "undefined") {
            customBlocks(Blockly);
            // Diğer kodlar buraya gelecek
        } else {
            console.error("Blockly nesnesi tanımlı değil.");
        }
        
        
        workspace.value.addChangeListener((event) => {
            // Değişiklik olaylarını kontrol edin
            if (
                event.type === Blockly.Events.CREATE || 
                event.type === Blockly.Events.DELETE ||
                event.type === Blockly.Events.CHANGE ||
                event.type === Blockly.Events.MOVE
            )

            {
                // Blockly çalışma alanındaki tüm blokların kodunu oluştu
                allGeneratedCode = javascriptGenerator.workspaceToCode(workspace.value);

                //include kısmını ayırıp oluştur ½½  asdfas ½½
                if (allGeneratedCode.match(/½½(.*?)½½/g) !== null)
                {
                    var include = allGeneratedCode.match(/½½(.*?)½½/g).map(function(val) {
                      return val.slice(2, -2); // ## işaretlerini kaldırıp sadece içerikleri alıyoruz
                    }).join('\n'); // Dizi içindeki değerleri birleştiriyoruz
                }
                else {
                  include="";
                }
                //setup kısmını ayırıp oluştur {{  asdfas {{
                if (allGeneratedCode.match(/{{(.*?){{/g) !== null)
                {
                    // ## işaretleri arasındaki değerleri almak için bir regex kullanabiliriz
                    var setup = allGeneratedCode.match(/{{(.*?){{/g).map(function(val) {
                      return val.slice(2, -2); // ## işaretlerini kaldırıp sadece içerikleri alıyoruz
                    }).join('\n'); // Dizi içindeki değerleri birleştiriyoruz
                }
                else {
                  setup="";
                }
                

                var loop="";
                // Düzenli ifadeyi tanımla
                var duzenliIfade = /½½(.*?)½½|{{(.*?){{/g; //tüm kodlardan setup ve include kısımlarını çıkartıp loop aldık
                // Metindeki tüm eşleşmeleri çıkar
                var loop = allGeneratedCode.replace(duzenliIfade, '');
                loop=loop.trim();
                // Elde edilen kodu istediğiniz şekilde düzenleme veya işleme
                content.value = include+"\n\n"+ "void setup() {\n" + setup + "\n}\n\n"+ 'void loop() {\n' + loop + '\n}\n\n';
                allGeneratedCode="";
                include="";
                setup="";
                loop="";

            }


        });

    });
    

    onUnmounted(() => {
      if (workspace.value) {
        workspace.value.dispose();
      }
    });

    return {
        blocklyDiv,
        workspace,
        content,
        include,
        setup,
        loop,
        
    };
  },
};
</script>
    
    
  
  <style scoped>
/* Blockly bileşeninin stilleri */

</style>
  